<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="grid">
          <div class="col-4">
            <Panel header="Add credits with GoChyp">
              <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
                <!-- Amount -->
                <div class="field pb-2 pt-2">
                  <div class="p-float-label">
                    <InputText id="amount" v-model="v$.form.amount.$model"
                               :class="{'p-invalid':v$.form.amount.$invalid && submitted}"/>
                    <label for="amount" :class="{'p-error':v$.form.amount.$invalid && submitted}">Amount*</label>
                  </div>

                  <span v-if="v$.form.amount.$error && submitted">
                    <span id="email-error" v-for="(error, index) of v$.form.amount.$errors" :key="index">
                      <small class="p-error">{{ error.$message.replace('Value', 'Amount') }}</small>
                    </span>
                  </span>
                </div>

                <p>Min amount: <b>{{ minAmount }} $</b></p>
                <p>Fee amount: <b>{{ fee }} %</b></p>
                <p>Total: <b>{{ total }} $</b></p>

                <Button type="submit" label="Submit" class="mt-2"/>
              </form>
            </Panel>
          </div>
          <div class="col-8">
            <Panel header="Add credits instantly with your debit or credit card.">
              <p>We now accept all major Credit Cards via our portal utilizing <b>GoChyp</b> Payment Processing. <b>GoChyp</b>
                is one of the largest worldwide leaders in online payment processing.</p>
              <p><b>Your credits will be automatically applied to your account</b>, no need to contact us using this
                method.</p>
              <p>So go ahead use your favorite card and get those free airline miles ✈️ or cash back ✌</p>
              <p>Any chargebacks will be rebutted. If you have any issues with an order, please message us first for a
                refund. We are happy to work with you.</p>
              <p>Have questions about <b>GoChyp.com</b> Payment Processing Gateway? Check out their site.</p>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {minValue, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {goChyp} from "@http/PaymentMethodResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import dataTable from "@/mixins/dataTable";
import CollectJSService from "@/services/CollectJSService";
import {mapActions} from "vuex";

export default {
  setup: () => ({v$: useVuelidate()}),

  mixins: [dataTable],

  data() {
    return {
      fee: null,
      minAmount: null,

      form: {
        amount: null,
      },
      submitted: false,
    }
  },

  collectJS: null,

  validations() {
    return {
      form: {
        amount: {
          required,
          minValue: minValue(this.minAmount),
        },
      }
    }
  },

  computed: {
    total() {
      let total = ((this.form.amount / 100) * this.fee) + (this.form.amount * 1);
      return Math.round(total * 100) / 100;
    }
  },

  methods: {
    ...mapActions({
      company: 'company/company',
    }),
    createCollectJS(token) {
      this.collectJS = new CollectJSService(token);
    },

    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.collectJS.start(data => {
        goChyp.createRequest({
          amount: this.form.amount,
          token: data.token,
        })
          .then(() => {
            this.$toast.add(toastParamBuilder.success('Successfully!'));
            this.resetForm();
            this.company();
          })
          .catch((error) => {
            this.$toast.add(toastParamBuilder.error(error.response?.data?.message));
          });
      });
    },

    resetForm() {
      this.form.amount = null;
      this.submitted = false;
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      let form = await goChyp.getRequest();
      await next((vm) => {
        vm.minAmount = form.data.min_amount;
        vm.fee = form.data.fee;
        vm.createCollectJS(form.data.token);
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/demo/badges.scss';
::v-deep(#zelle-payment-request-table .p-panel-content) {
  padding: 0;
}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
