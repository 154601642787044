export default class CollectJSService {
  constructor(token) {
    let script = document.createElement('script');
    script.setAttribute('id', 'collect-script');
    script.setAttribute('src', 'https://ppt.transactiongateway.com/token/Collect.js');
    script.setAttribute('data-tokenization-key', token);
    document.head.appendChild(script);
  }

  start(callback) {
    window.CollectJS.configure({paymentType: 'cc', callback});
    window.CollectJS.startPaymentRequest();
  }
}
